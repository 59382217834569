import cls from 'classnames'
import { useMemo } from 'react'

import styles from './otp-input.module.css'

// Only allow digits
export const REGEX_DIGIT = new RegExp(/^\d+$/)

export type Props = {
  disabled?: boolean
  /**
   * OTP value, as a string
   */
  value: string
  /**
   * Length of the OTP code.
   */
  valueLength: number
  /**
   * Function to call when the input value changes.
   */
  onChange: (value: string) => void
  /**
   * The variant of the OTP form.
   */
  variant: 'light' | 'dark'
}

export default function OtpInput({
  value,
  valueLength,
  onChange,
  disabled,
  variant,
}: Props) {
  /**
   * `value` is a string of numbers that we convert to an array
   * so we can display them as individual input boxes
   */
  const valueItems = useMemo(() => {
    const valueArray = value.split('')
    const items: Array<string> = []

    // Our array must be the length of `valueLength`
    for (let i = 0; i < valueLength; i++) {
      const char = valueArray[i]

      if (REGEX_DIGIT.test(char)) {
        items.push(char)
      } else {
        // If there's no number, we'll have an empty string
        items.push('')
      }
    }

    return items
  }, [value, valueLength])

  const focusToNextInput = (target: HTMLElement) => {
    const nextElementSibling =
      target.nextElementSibling as HTMLInputElement | null

    if (nextElementSibling) {
      nextElementSibling.focus()
    } else {
      target.blur()
    }
  }

  const focusToPrevInput = (target: HTMLElement) => {
    const previousElementSibling =
      target.previousElementSibling as HTMLInputElement | null

    if (previousElementSibling) {
      previousElementSibling.focus()
    }
  }

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    idx: number
  ) => {
    const target = e.target
    let targetValue = target.value.trim()
    const isTargetValueDigit = REGEX_DIGIT.test(targetValue)

    // Only allow numbers or empty value, which means the user is deleting
    if (!isTargetValueDigit && targetValue !== '') {
      return
    }

    const nextInputEl = target.nextElementSibling as HTMLInputElement | null

    // Only delete digit if next input element has no value
    // Users can still replace digits, but we won't support deleting in the middle
    if (!isTargetValueDigit && nextInputEl && nextInputEl.value !== '') {
      return
    }

    // Replace a "delete" with a space {' '} so that the values
    // from other input boxes won't break
    targetValue = isTargetValueDigit ? targetValue : ' '

    const targetValueLength = targetValue.length

    if (targetValueLength === 1) {
      /**
       * Construct the new value prop string by using the index of the input
       */
      const newValue =
        value.substring(0, idx) + targetValue + value.substring(idx + 1)

      onChange(newValue)

      if (!isTargetValueDigit) {
        return
      }

      focusToNextInput(target)
    }
    // Only allow paste when the copied text is a digit
    // and the same length as our valueLength
    else if (targetValueLength === valueLength) {
      onChange(targetValue)

      // Once the code is pasted, we don't need to focus anymore
      target.blur()
    }
  }

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const { key } = e
    const target = e.target as HTMLInputElement

    // Supports moving with arrow keys
    if (key === 'ArrowRight' || key === 'ArrowDown') {
      e.preventDefault()
      return focusToNextInput(target)
    }
    if (key === 'ArrowLeft' || key === 'ArrowUp') {
      e.preventDefault()
      return focusToPrevInput(target)
    }

    const targetValue = target.value

    // Keep the selection range position
    // if the same digit was typed
    target.setSelectionRange(0, targetValue.length)

    if (e.key !== 'Backspace' || target.value !== '') {
      return
    }

    focusToPrevInput(target)
  }

  const handleFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    const { target } = e

    // Focus should be on the last input that has a value
    const prevInputEl = target.previousElementSibling as HTMLInputElement | null

    if (prevInputEl && prevInputEl.value === '') {
      return prevInputEl.focus()
    }

    target.setSelectionRange(0, target.value.length)
  }

  return (
    <div className={styles.otpGroup}>
      {valueItems.map((digit, idx) => (
        <input
          disabled={disabled}
          // OS will parse the OTP in the SMS heuristically
          autoComplete="one-time-code"
          className={cls(styles.otpInput, styles[variant])}
          // changes the mobile keyboard to numbers only
          inputMode="numeric"
          key={idx}
          // Allows pasting of code and autocomplete logic
          maxLength={valueLength}
          name="one-time-code"
          pattern="\d{1}"
          type="text"
          value={digit}
          onChange={(e) => {
            handleInputChange(e, idx)
          }}
          onKeyDown={handleKeyDown}
          onFocus={handleFocus}
        />
      ))}
    </div>
  )
}
